.channel-post-link {
  text-decoration: none;
  color: #397dff;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.05em;
  color: #397dff;
  @apply cursor-pointer inline-block overflow-hidden overflow-ellipsis;
}

.channel-post-link-copy-btn {
  box-sizing: border-box;
  width: 52px;
  height: 30px;
  background: #3975f8;
  border: 2px solid #3975f8;
  color: white;
}

.channel-post-link-copy-btn:hover {
  box-sizing: border-box;
  width: 52px;
  height: 30px;
  background: #0037ad;
  border: 2px solid #0037ad;
  color: white;
}

.channel-post-link-share-btn {
  width: 30px;
  height: 30px;
  @apply cursor-pointer;
}

.unsubscribe-page {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.05em;
  color: #000;
  padding-left: 43px;
  padding-top: 59px;
  @apply flex flex-col gap-10;
}

.message {
  @apply text-xl;
}

.email-address {
  color: deepskyblue;
}

.channel-post-container {
  min-width: 600px;
}

.channel-post-list-table {
  @apply table;
  table-layout: fixed;
}

.channel-post-column-title {
  @apply text-center;
}

.channel-post-thumbnail {
  display: inline-block;
  width: 100px;
  height: 56px;
}

.channel-post-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.channel-post-title {
  flex: 1;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.channel-post-date {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.05em;
  color: #000000;
}

a {
  text-decoration: none;
  color: #397dff;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.05em;
  color: #397dff;
}

.swal2-html-container {
  margin: 0px !important;
}

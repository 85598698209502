.login-page {
  @apply h-screen;
}

.login-page .login-form-container {
  @apply bg-black;
  height: 60%;
}

.login-page .bottom-container {
  @apply bg-white;
  height: 40%;
}

.languages-panel {
  background: #e8e8e8;
  border: 1px solid #e5e6e8;
  border-radius: 10px;
  padding: 1px;
  height: 38px;
  @apply flex justify-center;
}

.languages-panel .segment {
  min-width: 100px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.05em;
  color: #929191;
  border: none;
  @apply flex justify-center items-center cursor-pointer;
}

.languages-panel .segment:first-child {
  border-radius: 8px 0px 0px 8px;
}

.languages-panel .segment:last-child {
  border-radius: 0px 8px 8px 0px;
}

.languages-panel .segment.selected {
  color: #000000;
  @apply bg-white;
}
